<template>
  <div class="flex justify-evenly items-center text-center bg-white q-pa-md">
    <div>
      <img src="../assets/gompels-core-logo.webp" height="50" alt="gompels-core-logo"/>
    </div>
    <div>
      <p class="text-gompels-green q-ma-none">Proudly powered by Gompels CORE</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GompelsCoreFooter'
})
</script>

<style scoped lang="scss">

</style>