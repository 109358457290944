<template>
  <footer class="row q-pb-xl q-pa-xl-lg q-pa-md d-print-none footer-wrapper">
    <div class="col footer-container">
      <h1 class="q-pa-md q-ma-none text-bold">
        <span class="pharmacy-name">Homeward </span>
        <small class="text-muted">Pharmacy</small>
      </h1>
      <p class="q-pa-md">
        PO Box 17531, REDDITCH<br/>
        pharmacy.fl377@nhs.net<br/>
      </p>
      <router-link :to="{name: 'privacy-notice'}" class="q-pa-md privacy-notice-link">
        Privacy Statement
      </router-link>
    </div>
    <div class="col ml-auto text-right gt-sm">
      <img class="q-py-lg q-pr-lg" src="../assets/winner-2023-small.png"/>
      <br/>
      <img class="q-py-lg q-pr-lg" src="../assets/npa-logo-white.webp"/>
      <br/>
      <a href="https://nhs.uk">
        <img class="q-py-lg q-pr-lg" src="../assets/nhs-logo-blue.webp"/>
      </a>
      <br/>
      <img class="q-py-lg q-pr-lg" src="../assets/numark-logo-white.webp"/>
    </div>
  </footer>
</template>

<script>
export default {
  // name: 'ComponentName',
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.footer-wrapper {
  background-color: #4a4f55;
  color: #ccc;
}

.footer-container {
  line-height: 1.8;
  font-size: 1rem;
}

.pharmacy-name {
  font-size: 50%;
}

.text-muted {
  font-size: 30%;
  font-weight: 400;
  color: #6c757d;
}

.privacy-notice-link {
  color: #ccc;
}
</style>