<template>
  <router-view />
  <PageFooter />
  <GompelsCoreFooter />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PageFooter from 'components/PageFooter.vue';
import GompelsCoreFooter from 'components/GompelsCoreFooter.vue';

export default defineComponent({
  name: 'MainLayout',

  components: { GompelsCoreFooter, PageFooter },

  setup() {
    return {};
  },
});
</script>
